import axios from 'axios';
import * as Sentry from '@sentry/react';

const TOKEN_KEY = "loginToken";
const REMEMBER_ME = "rememberMe";
const EMAIL_CONFIRMED = "isEmailConfirmed";

export const login = (token, rememberMe) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REMEMBER_ME, rememberMe);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REMEMBER_ME);
  localStorage.removeItem(EMAIL_CONFIRMED);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const EmailConfirmation = (isConfirm, token) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EMAIL_CONFIRMED, isConfirm);
};

export const isLogin = () => {
  if (localStorage.getItem(REMEMBER_ME) === "true" ||
    document.referrer.includes("login") ||
    (document.referrer.includes("freelancer") && localStorage.getItem('loginToken')) ||
    (document.referrer.includes("email-confirmation") && localStorage.getItem(EMAIL_CONFIRMED) === "true")) {
    return true;
  }
  return false;
};

export const setSlug = (slug) => {
  if (localStorage.getItem('loginToken') == null) {
    sessionStorage.setItem('slug', slug)
  }
}

// Create an axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_ROUTE,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  mode: 'cors',
  credentials: 'include'
});

// Add a request interceptor to include the authorization token
apiClient.interceptors.request.use(config => {
  config.headers['Authorization'] = 'Bearer ' + getToken();
  return config;
}, error => {
  return Promise.reject(error);
});

// Add a response interceptor to capture responses and errors
apiClient.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    // You can add more details to Sentry using Sentry.setContext or Sentry.addBreadcrumb
    Sentry.setContext("Response Details", {
      status: error.response.status,
      data: error.response.data,
      headers: error.response.headers
    });
    Sentry.setUser({
      id: localStorage.getItem("resource_id"),
      username: localStorage.getItem("email")
    });
    Sentry.captureException(error); // Log error to Sentry
  } else if (error.request) {
    Sentry.setContext("Request Details", {
      request: error.request
    });
    Sentry.setUser({
      id: localStorage.getItem("resource_id"),
      username: localStorage.getItem("email")
    });
    Sentry.captureException(error); // Log error to Sentry
  } else {
    Sentry.captureException(error); // Log error to Sentry
  }
  return Promise.reject(error);
});

export { apiClient };